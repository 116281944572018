import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { loadLoyaltyCouponNames } from '@wix/loyalty-coupon-names';
import { loyaltyMyRewardsOpen } from '@wix/bi-logger-loyalty-uou/v2';
import {
  createMembersAreaWidgetPluginService,
  createWidgetPluginExports,
} from '@wix/members-area-widget-plugin-lib/viewer';

import { RewardOrigin } from '../../types/domain';
import { SettingsEvents, RewardsTabState } from '../../types/settings';
import {
  createClaimedCouponTitleBuilder,
  createCurrencyFormatter,
  createRewardDescriptionBuilder,
  getAppInstallStatus,
  getDemoContent,
  getLocale,
  isRewardAvailable,
  loadData,
  sortRewards,
  toSimpleAccount,
  toSimpleRewards,
} from '../../utils';
import { createStore, getActionHandlers } from './Widget/store';
import type { ControllerProps } from './Widget';
import { RequestStatus } from '../../types/store';
import { rewardsSlice } from './Widget/store/slices';

const createController: CreateControllerFn = async ({ controllerConfig, flowAPI, widgetConfig }) => {
  const { config, setProps, wixCodeApi } = controllerConfig;
  const { isEditor, isPreview, isViewer } = flowAPI.environment;
  const { httpClient } = flowAPI;

  const widgetPluginService = createMembersAreaWidgetPluginService();
  const componentEventHandler = createEventHandler<SettingsEvents>(config.publicData.COMPONENT || {});
  const setWidgetProps = (props: Partial<ControllerProps>) => {
    setProps(props);
  };
  const formatCurrency = createCurrencyFormatter(flowAPI);

  componentEventHandler.on('rewardsTabState', (rewardsTabState: RewardsTabState) =>
    setWidgetProps({
      rewardsTabState,
    }),
  );
  componentEventHandler.onReset(() =>
    setWidgetProps({
      rewardsTabState: RewardsTabState.Default,
    }),
  );

  // See: https://wix.slack.com/archives/C01480U2SAF/p1643623573887269
  setProps({ fitToContentHeight: true });

  const pageReady = async () => {
    try {
      const isWidgetPlugin = widgetPluginService.getIsWidgetPlugin();
      const [appInstallStatus, couponNames] = await Promise.all([
        getAppInstallStatus(httpClient),
        loadLoyaltyCouponNames({ i18n: flowAPI.translations.i18n, formatCurrency }),
      ]);

      const useDemoContent = isEditor || isPreview;
      const {
        loyaltyProgram,
        account,
        rewards,
        claimedCoupons,
        referralCouponRewards,
        couponEntityNames,
        tiersProgram,
      } = useDemoContent
        ? await getDemoContent({ flowAPI, appInstallStatus })
        : await loadData({ flowAPI, appInstallStatus });

      const store = createStore(
        {
          flowAPI,
          wixCodeApi,
          couponNames,
        },
        {
          accountConfig: {
            fetchAccountStatus: RequestStatus.IDLE,
            account: toSimpleAccount(account),
          },
          couponsConfig: {
            redeemCouponStatus: RequestStatus.IDLE,
            claimedCoupons,
          },
          rewardsConfig: {
            simpleRewards: [],
            rawRewards: rewards,
          },
          loyaltyProgramConfig: {
            program: loyaltyProgram ?? {},
          },
          tiersProgramConfig: {
            programSettings: tiersProgram.programSettings ?? {},
            tiers: tiersProgram.tiers ?? [],
          },
          appInstallStatusConfig: appInstallStatus,
          localeConfig: {
            locale: getLocale(flowAPI),
          },
        },
      );

      const pointsBalance = account.points?.balance ?? 0;

      const simpleRewards = toSimpleRewards({
        flowAPI,
        rewards,
        claimedCoupons,
        referralCouponRewards,
        couponEntityNames,
        buildRewardDescription: createRewardDescriptionBuilder({
          loyaltyProgram,
          flowAPI,
          couponNames,
          appInstallStatus,
        }),
        buildClaimedCouponTitle: createClaimedCouponTitleBuilder(couponNames),
      });

      flowAPI.bi?.updateDefaults({
        totalPoints: pointsBalance,
        totalRewards: simpleRewards.filter((r) => r.rewardOrigin === RewardOrigin.USER).length,
        availableRewards: simpleRewards
          .filter((r) => r.rewardOrigin === RewardOrigin.USER)
          .filter((r) => isRewardAvailable({ requiredPoints: r.requiredPoints, pointsBalance }))
          .map((r) => r.id)
          .join(','),
        unavailableRewards: simpleRewards
          .filter((r) => r.rewardOrigin === RewardOrigin.USER)
          .filter((r) => !isRewardAvailable({ requiredPoints: r.requiredPoints, pointsBalance }))
          .map((r) => r.id)
          .join(','),
      });

      const sortedRewards = sortRewards({
        flowAPI,
        rewards: simpleRewards,
        pointsBalance,
      });

      store.dispatch(rewardsSlice.actions.setSimpleRewards(sortedRewards));

      setWidgetProps({
        ...store.getState(),
        ...getActionHandlers(store),
        isWidgetPlugin,
      });

      store.subscribe(() =>
        setWidgetProps({
          ...store.getState(),
        }),
      );
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        flowAPI.reportError(e);
      }
      setProps({
        hasError: true,
      });
    }

    if (isViewer) {
      flowAPI.bi?.report(loyaltyMyRewardsOpen({}));
    }
  };

  return {
    async pageReady() {
      const isRendered = widgetPluginService.getIsRendered();
      if (!isRendered) {
        return;
      }

      await pageReady();
    },
    updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
    },
    exports: () => createWidgetPluginExports(widgetPluginService, pageReady),
  };
};

export default createController;
