import { createSlice } from '@reduxjs/toolkit';
import { AppInstallStatus } from '../../../../../../utils/getAppInstallStatus';

export type AppInstallStatusProps = AppInstallStatus;

const initialState: AppInstallStatus = {
  isLoyaltyAppInstalled: false,
  isReferralsAppInstalled: false,
  isStoresAppInstalled: false,
  isEventsAppInstalled: false,
  isBookingsAppInstalled: false,
  isPricingPlansAppInstalled: false,
  isOldRestaurantsAppInstalled: false,
  isNewRestaurantsAppInstalled: false,
};

export const appInstallStatusSlice = createSlice({
  name: 'appInstallStatus',
  initialState,
  reducers: {},
});
