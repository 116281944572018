import { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  WIX_NEW_STORES as WIX_STORES_APP_DEF_ID,
  WIX_EVENTS as WIX_EVENTS_APP_DEF_ID,
  WIX_BOOKINGS as WIX_BOOKINGS_APP_DEF_ID,
  WIX_RESTAURANTS_ORDERS as WIX_OLD_RESTAURANTS_APP_DEF_ID,
  WIX_RESTAURANTS_ORDERS_NEW as WIX_NEW_RESTAURANTS_APP_DEF_ID,
  PRICING_PLANS as PRICING_PLANS_APP_DEF_ID,
} from '@wix/app-definition-ids';

import { queryInstalledApps } from '../api/installed-apps.api';
import { LOYALTY_APP_DEFINITION_ID, REFERRALS_APP_DEFINITION_ID } from '../constants';

export interface AppInstallStatus {
  isLoyaltyAppInstalled: boolean;
  isReferralsAppInstalled: boolean;
  isStoresAppInstalled: boolean;
  isEventsAppInstalled: boolean;
  isBookingsAppInstalled: boolean;
  isPricingPlansAppInstalled: boolean;
  isOldRestaurantsAppInstalled: boolean;
  isNewRestaurantsAppInstalled: boolean;
}

const fetchInstalledAppsMap = async (httpClient: IHttpClient) => {
  const {
    data: { appsMap },
  } = await httpClient.request(
    queryInstalledApps({
      appDefIds: [
        WIX_STORES_APP_DEF_ID,
        WIX_EVENTS_APP_DEF_ID,
        WIX_BOOKINGS_APP_DEF_ID,
        WIX_OLD_RESTAURANTS_APP_DEF_ID,
        WIX_NEW_RESTAURANTS_APP_DEF_ID,
        PRICING_PLANS_APP_DEF_ID,
        LOYALTY_APP_DEFINITION_ID,
        REFERRALS_APP_DEFINITION_ID,
      ],
    }),
  );

  return appsMap;
};

export async function getAppInstallStatus(httpClient: IHttpClient): Promise<AppInstallStatus> {
  const appsMap = await fetchInstalledAppsMap(httpClient);

  return {
    isLoyaltyAppInstalled: appsMap[LOYALTY_APP_DEFINITION_ID],
    isReferralsAppInstalled: appsMap[REFERRALS_APP_DEFINITION_ID],
    isStoresAppInstalled: appsMap[WIX_STORES_APP_DEF_ID],
    isEventsAppInstalled: appsMap[WIX_EVENTS_APP_DEF_ID],
    isBookingsAppInstalled: appsMap[WIX_BOOKINGS_APP_DEF_ID],
    isPricingPlansAppInstalled: appsMap[PRICING_PLANS_APP_DEF_ID],
    isOldRestaurantsAppInstalled: appsMap[WIX_OLD_RESTAURANTS_APP_DEF_ID],
    isNewRestaurantsAppInstalled: appsMap[WIX_NEW_RESTAURANTS_APP_DEF_ID],
  };
}
